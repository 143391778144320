'use client';
import formatDate from 'date-fns/format';
import { usePathname } from 'next/navigation';
import type { JSX } from 'react';
import docs from '#/lib/data/docs.json';
import guides from '#/lib/data/guides.json';
import styles from './lastupdated.module.css';

export function LastUpdated({
  source = 'docs',
}: {
  source?: 'docs' | 'guides';
}): JSX.Element {
  const pathname = usePathname() as string;
  const matchpath =
    source === 'docs'
      ? docs.filter(
          (doc) =>
            pathname === doc.filePath ||
            `${pathname}/index` === doc.filePath ||
            `${pathname}/overview` === doc.filePath,
        )
      : guides.filter(
          (guide) =>
            pathname === guide.filePath ||
            `${pathname}/index` === guide.filePath ||
            `${pathname}/overview` === guide.filePath,
        );
  let lastupdated = '';
  if (matchpath.length > 0) {
    const matchPathLastEdited = matchpath[0].lastEdited;
    if (matchPathLastEdited) lastupdated = matchPathLastEdited;
  }
  return (
    <div className={styles.lastUpdatedSection}>
      {lastupdated === '' ? (
        <div className={styles.lastUpdatedText}>
          Last updated on {formatDate(new Date(), 'MMMM d, yyyy')}
        </div>
      ) : (
        <div className={styles.lastUpdatedText}>
          Last updated on {formatDate(new Date(lastupdated), 'MMMM d, yyyy')}
        </div>
      )}
    </div>
  );
}
